<template>
  <a-layout>
    <a-layout-header style="background: #fff; padding: 0"/>
    <a-layout-content style="margin: 0 16px;">
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>网站信息管理</a-breadcrumb-item>
      </a-breadcrumb>
      <!--            <div class="toolsbar">-->
      <!--                <div style="float: left;margin-left: 20px;margin-top: 10px;">-->
      <!--                    <a-button type="primary" @click="addadmin()">新建文章</a-button>-->
      <!--                    <a-button type="primary" :loading="loading" @click="start" style="margin-left: 20px">-->
      <!--                        刷新-->
      <!--                    </a-button>-->
      <!--                </div>-->
      <!--                <div class="serach_input">-->
      <!--                    <a-input-group compact>-->
      <!--                        <a-select defaultValue="文章名">-->
      <!--                            <a-select-option value="username">文章名</a-select-option>-->
      <!--                            &lt;!&ndash;                    <a-select-option value="username">文章名</a-select-option>&ndash;&gt;-->
      <!--                        </a-select>-->
      <!--                        <a-input-search style="width: 64%" defaultValue @search="onSearch" enterButton/>-->
      <!--                    </a-input-group>-->
      <!--                </div>-->
      <!--            </div>-->
      <a-row>
        <a-col :push="0" :span="24">
          <a-tabs default-active-key="1" @change="callback">
            <a-tab-pane key="1" tab="Links">
              <div style="padding: 24px; background-color: #fff;margin-bottom:20px">
                <a-button  html-type="submit"  type="primary" @click="addLinks" style="margin-right: 20px;margin-bottom:20px;">
                  增加
                </a-button>
                <a-button  html-type="submit"  type="primary" @click="start" style="margin-right: 20px">
                  刷新
                </a-button>

                <a-table :columns="columns" :data-source="linksData" bordered  rowKey="id" :loading="loading">
                  <template slot="cover_image" slot-scope="text, index" style="text-align: center">
                    <img :src="text" alt="" style="width: 100%"/>
                  </template>

                  <template slot="operation" slot-scope="text, index">
                    <span style="margin-right: 20px">
                             <a @click="editLinks(index)">编辑</a>
                            </span>
                    <span>
                             <a @click="delitem(index)">剔除</a>
                            </span>
                  </template>
                </a-table>


              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Cooperation" force-render>
              <div style="padding: 24px; background-color: #fff;margin-bottom:20px">
                <a-button  html-type="submit"  type="primary" @click="addCooperation" style="margin-right: 20px;margin-bottom:20px;">
                  增加
                </a-button>
                <a-button  html-type="submit"  type="primary" @click="start" style="margin-right: 20px">
                  刷新
                </a-button>
                <a-table :columns="columns" :data-source="CooperationData" bordered   rowKey="id">
                  <template slot="cover_image" slot-scope="text, index" style="text-align: center">
                    <img :src="text" alt="" style="width: 100%"/>
                  </template>

                  <template slot="operation" slot-scope="text, index">
                    <span style="margin-right: 20px">
                             <a @click="editCooperation(index)">编辑</a>
                            </span>
                    <span>
                             <a @click="delitem(index)">剔除</a>
                            </span>
                  </template>
                </a-table>

                <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21}">
                  <a-button  html-type="submit"  type="primary" @click="addCooperation" style="margin-right: 20px">
                    增加
                  </a-button>
                </a-form-item>
              </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="webinfos" force-render>
              <div style="padding: 24px; background-color: #fff;margin-bottom:20px">
                <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="关于我们">
                  <a-textarea
                      v-model="siteSinfo.about"
                      placeholder="about us"
                      :auto-size="{ minRows: 3, maxRows: 5 }"
                  />
                </a-form-item>

                <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="联系方式">
                  <a-input v-model="siteSinfo.email"/>
                </a-form-item>
                <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="电子邮件">
                  <a-input v-model="siteSinfo.tel"/>
                </a-form-item>
                <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="地址">
                  <a-input v-model="siteSinfo.adder"/>
                </a-form-item>
                <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 15}">
                  <a-button  html-type="submit"  type="primary" @click="sysinfo_editok">
                    保 存
                  </a-button>
                </a-form-item>
              </div>
            </a-tab-pane>
<!--            <a-tab-pane key="4" tab="Introduction" force-render>-->
<!--              <div style="padding: 24px; background-color: #fff;margin-bottom:20px">-->
<!--                <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="封面图">-->
<!--&lt;!&ndash;                  {{IntroductionfileList.length}}&ndash;&gt;-->
<!--                  <a-upload-->
<!--                      :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/Links'"-->
<!--                      :file-list="IntroductionfileList"-->
<!--                      class="ant-uploads"-->
<!--                      list-type="picture-card"-->
<!--                      @change="handIntroductionleChange"-->
<!--                      @preview="handlePreview"-->
<!--                  >-->
<!--                    <div v-if="IntroductionfileList.length < 1">-->
<!--                      <a-icon type="plus"/>-->
<!--                      <div class="ant-upload-text">-->
<!--                        upload-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a-upload>-->
<!--                </a-form-item>-->
<!--                <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="标题">-->
<!--                  <a-input v-model="Introduction.title"/>-->
<!--                </a-form-item>-->

<!--                <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="内容">-->
<!--                  <a-textarea-->
<!--                      v-model="Introduction.content"-->
<!--                      placeholder="Introduction"-->
<!--                      :auto-size="{ minRows: 3, maxRows: 5 }"-->
<!--                  />-->
<!--                </a-form-item>-->
<!--                <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="跳转链接">-->
<!--                  <a-input v-model="Introduction.url"/>-->
<!--                </a-form-item>-->
<!--                <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 15}">-->
<!--                  <a-button  html-type="submit"  type="primary" @click="Introduction_editok">-->
<!--                    保 存-->
<!--                  </a-button>-->
<!--                </a-form-item>-->
<!--              </div>-->
<!--            </a-tab-pane>-->
          </a-tabs>



        </a-col>
      </a-row>

    </a-layout-content>
    <a-layout-footer style="text-align: center"></a-layout-footer>
    <a-modal v-model="pop_Linksadd" title="新增链接" width="45%" @ok="item_addok(3)">
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="图标">
        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/Links'"
            :file-list="fileList"
            class="ant-uploads"
            list-type="picture-card"
            @change="handitemleChange"
            @preview="handlePreview"
        >
          <div v-if="fileList.length < 1">
            <a-icon type="plus"/>
            <div class="ant-upload-text">
              upload
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="名字">
        <a-input v-model="item.title"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="链接">
        <a-input v-model="item.url" placeholder="http://"/>
      </a-form-item>
    </a-modal>
    <a-modal v-model="pop_Cooperationadd" title="新增合作伙伴" width="45%" @ok="item_addok(4)">
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="图标">
        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/Cooperation'"
            :file-list="fileList"
            class="ant-uploads"
            list-type="picture-card"
            @change="handitemleChange"
            @preview="handlePreview"
        >
          <div v-if="fileList.length < 1">
            <a-icon type="plus"/>
            <div class="ant-upload-text">
              upload
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="名字">
        <a-input v-model="item.title"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="链接">
        <a-input v-model="item.url" placeholder="http://"/>
      </a-form-item>
    </a-modal>
    <a-modal v-model="pop_Linksedit" title="编辑链接" width="45%" @ok="item_editok(3)">
      {{item}}
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="图标">
        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/Links'"
            :file-list="fileList"
            class="ant-uploads"
            list-type="picture-card"
            @change="handitemleChange"
            @preview="handlePreview"
        >
          <div v-if="fileList.length < 1">
            <a-icon type="plus"/>
            <div class="ant-upload-text">
              upload
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="名字">
        <a-input v-model="item.title"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="链接">
        <a-input v-model="item.url" placeholder="http://"/>
      </a-form-item>
    </a-modal>
    <a-modal v-model="pop_Cooperationedit" title="编辑合作伙伴" width="45%" @ok="item_editok(4)">
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="图标">
        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/Cooperation'"
            :file-list="fileList"
            class="ant-uploads"
            list-type="picture-card"
            @change="handitemleChange"
            @preview="handlePreview"
        >
          <div v-if="fileList.length < 1">
            <a-icon type="plus"/>
            <div class="ant-upload-text">
              upload
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="名字">
        <a-input v-model="item.title"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="链接">
        <a-input v-model="item.url" placeholder="http://"/>
      </a-form-item>
    </a-modal>
  </a-layout>

</template>

<script>
// import moment from "moment";
// import {quillEditor} from "vue-quill-editor";
// import "quill/dist/quill.snow.css"; //quill编辑器的样式表
import Config from "../config";

import {Modal} from "ant-design-vue";
import Storage from "../common/storage";
// import * as Util from "../common/util";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
// const toolbarOptions = [
//   ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
//   // ["blockquote", "code-block"], //引用，代码块
//   [{header: 1}, {header: 2}], // 标题，键值对的形式；1、2表示字体大小
//   //[{ list: "ordered" }, { list: "bullet" }], //列表
//   //[{ script: "sub" }, { script: "super" }], // 上下标
//   [{indent: "-1"}, {indent: "+1"}], // 缩进
//   [{direction: "rtl"}], // 文本方向
//   [{size: ["small", false, "large", "huge"]}], // 字体大小
//   [{header: [1, 2, 3, 4, 5, 6, false]}], //几级标题
//   [{color: []}, {background: []}], // 字体颜色，字体背景颜色
//   [{font: []}], //字体
//   [{align: []}], //对齐方式
//   ["clean"], //清除字体样式
//   ["image"], //上传图片、上传视频
//   // ["image", "video"], //上传图片、上传视频
// ];
const columns = [
  {
    title: "logo图标",
    dataIndex: "cover_image",
    width: "15%",
    scopedSlots: {customRender: "cover_image"}
  },
  {
    title: "名字",
    dataIndex: "title",
    scopedSlots: {customRender: "title"}
  },{
    title: "链接地址",
    dataIndex: "url",
   scopedSlots: {customRender: "url"}
  },


  {
    title: '操作',
    width: "15%",
    key: 'operation',
    scopedSlots: {customRender: 'operation'}
  },
];
export default {

  name: "Dashboard",
  components: {
    // quillEditor
  },
  computed: {
    // editor() {
    //   return this.$refs.myQuillEditor.quill;
    // },
  },

  data() {
    return {
      columns,
      content: '', //文本区域内容
      // editorOption: {
      //   modules: {
      //     toolbar: {
      //       container: this.toolbarOptions || toolbarOptions, // 自定义工具栏，与上面定义的toolbarOptions 相对应
      //       handlers: {
      //         'image': function (value) {
      //           if (value) {
      //             console.log('你点到我了!')
      //             // 获取到antd的upload框选择图片的弹出框事件并触发一次点击
      //             document.querySelector('.ant-upload .ant-btn').click()
      //           } else {
      //             //禁用quill自带的image时间
      //             this.quill.format('image', false)
      //           }
      //         }
      //       } // 事件重写
      //     }
      //   },
      //   theme: "snow",  //主题
      //   placeholder: "请输入正文",
      // },

      pagination: {
        pageSize: 10
      },

      loading: false,
      pop_Linksadd: false,
      pop_Cooperationadd: false,
      pop_Linksedit: false,
      pop_Cooperationedit: false,

      siteSinfo: {
        about:"",
        email:"",
        tel:"",
        adder:"",
      },
      Introduction: {
        title:"",
        cover_img:"",
        content:"",
        url:"",
      },
      linksData: [],
      item:{
        id:"",
        title:"",
        url:"",
        cover_img:"",
      },
      infoData: [],
      CooperationData: [],
      PreviewVisible: false,
      uid: Storage.uid,
      token: Storage.token,
      server_url: Config.data.server_url,
      previewVisible: false,
      fileList: [],
      IntroductionfileList: [],
      previewImage: '',
      cover_img: '',

    };
  },
  created() {
    // 获取第一页内容
    //vue的初始化方法，页面一初始化，就去拿参数
    this.getSysinfo(1);
    this.getSysinfo(2);
    this.getSysinfo(3);
    this.getSysinfo(4);
    // this.category_id = this.$route.query.category_id
    // console.log("category_id", this.category_id);
  },

  methods: {
    callback(key) {
      console.log(key);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const { keys, names } = values;
          console.log('Received values of form: ', values);
          console.log(
              'Merged values:',
              keys.map(key => names[key]),
          );
        }
      });
    },
    onEditorChange({quill, html, text}) {
      console.log('editor change!', quill, html, text)
      this.content = html
    },
    handleChangeItem(info) {
      console.log('info', info);
      //这一步是获取quilleditor的实例
      let quill = this.$refs.myQuillEditor.quill

      // 获取光标所在位置
      let length = quill.getSelection().index
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        this.loading = false;
        this.uploadImgUrl =this.server_url+info.file.response.src
        console.log(length)
        // 插入图片  res为服务器返回的图片地址
        quill.insertEmbed(length, 'image', this.uploadImgUrl)
        // 调整光标到最后
        quill.setSelection(length + 1)
        this.$message.success('上传成功')
        //console.log(this.url)
        // this.$message.error('图片插入失败')
      }
    },
    beforeUploaditem(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    },

    handleChange(info,id) {
      console.log("info", info);
      console.log("info", id);

      console.log(info.file.status);
      if (info.file.status === "removed") {
        console.log("剔除");
        this.CooperationData[id].cover_img = "";
        this.item.cover_img = "";
        return;
      }
      this.fileList=info.fileList ;
      for (var i = 0; i < this.fileList.length; i++) {
        if (info.file.status === "done" || info.file.status === "removed") {
          // this.CooperationData[id].cover_img = "";
          this.CooperationData[id].cover_img = this.server_url + this.fileList[i].response.src;
          this.item.cover_img = this.server_url + this.fileList[i].response.src;

        }
        /* eslint-enable */
        // console.log(info.fileList[i]);
        // console.log(info.fileList[i].response.src);
      }
      console.log(this.CooperationData[id].cover_img);
      console.log(this.item.cover_img);
    },
    handitemleChange(info) {
      console.log("info", info);

      console.log(info.file.status);
      if (info.file.status === "removed") {
        console.log("剔除");
        this.item.cover_img = "";
        this.fileList=[];
        return;
      }
      this.fileList=info.fileList ;
      for (var i = 0; i < this.fileList.length; i++) {
        if (info.file.status === "done" || info.file.status === "removed") {
          // this.CooperationData[id].cover_img = "";
          this.item.cover_img = this.server_url + this.fileList[i].response.src;

        }
        /* eslint-enable */
        // console.log(info.fileList[i]);
        // console.log(info.fileList[i].response.src);
      }
      console.log(this.item.cover_img);
    },
    handIntroductionleChange(info) {
      console.log("info", info);

      console.log(info.file.status);
      if (info.file.status === "removed") {
        console.log("剔除");
        this.IntroductionfileList=[];
        this.Introduction.cover_img = "";
        return;
      }
      this.IntroductionfileList=info.fileList ;
      for (var i = 0; i < this.IntroductionfileList.length; i++) {
        if (info.file.status === "done" || info.file.status === "removed") {
          // this.CooperationData[id].cover_img = "";
          this.Introduction.cover_img = this.server_url + this.IntroductionfileList[i].response.src;

        }
        /* eslint-enable */
        // console.log(info.fileList[i]);
        // console.log(info.fileList[i].response.src);
      }
      console.log(this.Introduction.cover_img);
    },

    async handlePreview(file) {
      console.log("剔除图片")
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.PreviewVisible = true;
    },
    
    start() {
      this.loading = true;
      this.data = [];
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
      this.getSysinfo(1);
      this.getSysinfo(2);
      this.getSysinfo(3);
      this.getSysinfo(4);
    },

    async onChange(pagination) {
      await this.doSearch(
          this.search_text,
          pagination.current - 1,
          pagination.pageSize
      );
    },


    async onChanges(e) {
      console.log(e.target.value);
    },


    async getSysinfo(type) {
      this.loading = true;
      let result = await this.$get("/api/admin/systemSet/list", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          type: type,
        }
      });

      if (result.status === true) {

          if( type === 1){
            this.siteSinfo = result.data[0].content;
            this.loading=false;
            return;
          }
          if( type === 2){
            this.Introduction = result.data[0].content;
            this.IntroductionfileList=[{
              uid: 0,
              name: 'name',
              status: 'done',
              url: this.Introduction.cover_img,
              response: {
                data: this.Introduction.cover_img
                // , JSON.parse(result.data.more_info_json)[k]
              }
            }]
            this.loading=false;
            return;
          }
          if( type === 3){
            this.linksData = result.data;
            this.loading=false;
            return;
          }
          if( type === 4){
            this.CooperationData = result.data;
            this.loading=false;
            return;
          }
          this.loading=false;

      }
    },


    addCooperation(){
      this.pop_Cooperationadd = true;
      this.fileList=[]
      this.previewImage=""
      this.item={
        title:"",
        url:"",
        cover_img:"",
      }

    },
    addLinks(){
      this.pop_Linksadd = true;
      this.fileList=[]
      this.previewImage=""
      this.item={
        title:"",
        url:"",
        cover_img:"",
      }
    },
    editCooperation(item){
      console.log(item)
      this.pop_Cooperationedit = true;
      this.fileList=[{
        uid: 0,
        name: 'name',
        status: 'done',
        url: item.cover_image,
        response: {
          data: item.cover_image
          // , JSON.parse(result.data.more_info_json)[k]
        }
      }]
      this.previewImage=""
      this.item={
        id:item.id,
        title:item.title,
        url:item.url,
        cover_img:item.cover_image,
      }

    },
    editLinks(item){
      console.log(item)
      this.pop_Linksedit = true;

      this.fileList=[{
        uid: 0,
        name: 'name',
        status: 'done',
        url: item.cover_image,
        response: {
          data: item.cover_image
          // , JSON.parse(result.data.more_info_json)[k]
        }
      }]
      this.previewImage=""
      this.item={
        id:item.id,
        title:item.title,
        url:item.url,
        cover_img:item.cover_image,
      }
    },

    async sysinfo_savaallok() {
      let result = await this.$post("/api/admin/systemSet/create", {
        uid: Storage.uid,
        token: Storage.token,
        save_value: {
          content: this.Introduction,
          type: 2,
        },
      });

      if (result.status === true) {
        // await this.getCategoryarticlelist(1, 20, this.Category_id, 0)
        Modal.info({
          title: "更新成功"
        });
      }

    },

    async sysinfo_editok() {
      let result = await this.$post("/api/admin/systemSet/update", {
        uid: Storage.uid,
        token: Storage.token,
        id: 6,
        save_value: {
          content: this.siteSinfo,
          type: 1,
        },
      });

      if (result.status === true) {
        // await this.getCategoryarticlelist(1, 20, this.Category_id, 0)
        Modal.info({
          title: "更新成功"
        });
      }

    },
    async Introduction_editok() {
      let result = await this.$post("/api/admin/systemSet/update", {
        uid: Storage.uid,
        token: Storage.token,
        id: 17,
        save_value: {
          content: this.siteSinfo,
          type: 2,
        },
      });

      if (result.status === true) {
        // await this.getCategoryarticlelist(1, 20, this.Category_id, 0)
        Modal.info({
          title: "更新成功"
        });
      }

    },
    async item_addok(type) {
      let result = await this.$post("/api/admin/systemSet/create", {
        uid: Storage.uid,
        token: Storage.token,
        save_value: {
          cover_image: this.item.cover_img,
          title: this.item.title,
          url: this.item.url,
          type: type,
        },
      });

      if (result.status === true) {
        // await this.getCategoryarticlelist(1, 20, this.Category_id, 0)
        Modal.info({
          title: "更新成功"
        });
        this.getSysinfo(3);
        this.getSysinfo(4);
        this.pop_Linksadd=false
            this.pop_Cooperationadd=false
      }

    },
    async item_editok(item) {
      let result = await this.$post("/api/admin/systemSet/update", {
        uid: Storage.uid,
        token: Storage.token,
        id: this.item.id,
        save_value: {
          cover_image: this.item.cover_img,
          title: this.item.title,
          url: this.item.url,
          type: item.type,
        },
      });

      if (result.status === true) {
        // await this.getCategoryarticlelist(1, 20, this.Category_id, 0)
        Modal.info({
          title: "更新成功"
        });
        this.getSysinfo(3);
        this.getSysinfo(4);
        this.pop_Linksedit=false
            this.pop_Cooperationedit=false
      }

    },
    async delitem(item) {
      var that = this;
      // console.log(item);
      Modal.confirm({
        title: "警告！",
        content: "是否确认剔除该用户?",

        onOk: async function () {

          let result = await that.$post("/api/admin/systemSet/delete", {

            uid: Storage.uid,
            token: Storage.token,
            id: item.id,
            // notes: this.edit_pop.notes,
            t: Date.now(),
            // sign: Crypt.sign([
            //     Storage.token,
            //     this.edit_pop.id,
            //     JSON.stringify(data),
            //     this.edit_pop.notes,
            //     this.$config.secret_key,
            //     now
            // ])
          });
          console.log(result);
          if (result.status === true) {
            console.log("准备弹窗！");
            that.getSysinfo(3);
            that.getSysinfo(4);
            Modal.info({
              title: "剔除成功"
            });
          }
          if (result.status === false) {
            // that.data = []
            // await this.doSearch(this.page, "1", "", this.estatedefault, "");
            Modal.info({
              title: result.msg
            });
          }
        }

      });


    },



  }
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.serach_input {
  width: 25vw;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
  margin-right: 25px;
}

.pages {
  float: right;
  margin-top: 10px;
}

.toolsbar {
  display: block;
  width: 100%;
  height: 42px;
  background: #fff
}

.ant-uploads /deep/ .ant-upload.ant-upload-select-picture-card {
  /*width: 200px !important;*/
  /*height: 200px !important;*/
}

.ant-uploads /deep/ .ant-upload-list-picture-card .ant-upload-list-item {
  /*width: 200px !important;*/
  /*height: 200px !important;*/
}

.ant-uploads /deep/ .ant-upload-list-picture-card-container {
  /*width: 200px !important;*/
  /*height: 200px !important;*/
}

.ant-tabs {
  overflow: unset !important;
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
